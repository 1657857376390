@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");

body {
  margin: 0;
  font-family: "Alliance", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Alliance";
  /*Can be any text*/
  src: local("Alliance"),
    url("./assets/font/AllianceNo2-Medium.ttf") format("truetype");
}